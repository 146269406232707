@import 'variables/colors.scss';
@import 'pricing.scss';

html, body {
  background: $grey-ligh-ter;
  font-family: 'Roboto', sans-serif;
}
.is-passlo { background-color: $passlo; }
.is-passlo .hero-head {
    background-image: linear-gradient(to bottom, #0050fe, #0059ff, #0061ff, #0069ff, $passlo);
    box-shadow: 0px 5px 20px 0px rgba(10, 10, 10, 0.1);
    z-index: 10;
}
.is-gradient {
    background-image: radial-gradient(circle at 50% 150%, $passlo 30%, $passlo-dark 100%);

    h1 {
        text-shadow: 1px 1px 10px rgba(10, 10, 10, 0.25);
    }
}
.is-darker { background-color: #252525; }
.is-darkest { background-color: #1E1E1E; }
.icon.is-info { color: rgba(16, 139, 221, 0.5); }
.icon.is-link { color: rgba(35, 100, 205, 0.5); }
.box-nobg {
    border: 0.05rem solid $grey-light;
    border-radius: 0.5rem;
    padding: 2rem;
    transition: border-color 0.2s ease-in-out;
}
.box-nobg .icon {
    margin-right: 2rem;
}
.box-nobg:hover {
    border-color: $passlo;
}
#main-hero {
    padding: 5rem 1rem 0.35rem 1rem;
}
.section > .container {
    padding-top: 0;

    .intro {
        padding-top: 2rem;
    }
}
.image {
    display: inline-block;
}
.image.is-rounded img {
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.navbar-brand {
    margin-right: 2rem;
    min-height: 5rem;

    .navbar-item img {
        max-height: 3.25rem;
    }
}
a.navbar-item:hover, a.navbar-link:hover,
a.navbar-item.is-active, a.navbar-link.is-active {
    background-color: #EFF3F4 !important;
    color: $passlo !important;
}
.navbar-menu .navbar-item {
    color: $white;
}
.navbar-menu.is-active .navbar-item {
    color: #333;
}
.button.demo {
    margin-left:1em;
    margin-top:0.55em;
}
.menu .menu-list a {
    border-bottom: 1px dotted $grey-light;
    transition: background-color 0.1s linear;
}
.menu .menu-list a .icon {
    margin-right: 0.4rem;
}
.menu .menu-list a:hover {
    background-color: $passlo;
    color: #fff;
}
.hero-body .title {
  color: hsl(192,17%,99%) !important;
}
.hero-body .subtitle {
  color: hsl(192,17%,99%) !important;
  padding-top: 2rem;
  line-height: 1.5;
}
.features {
  padding: 2.5rem 0;
}
.box.cta {
  border-radius: 0;
  border-left: none;
  border-right: none;
}
.card-image > .fa {
  font-size: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #209cee;
}
.card-content .content {
  font-size: 14px;
  margin: 1rem 1rem;
}
.card-content .content h4 {
  font-size: 16px;
  font-weight: 700;
}
.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 2rem;
}
.intro {
  padding: 5rem 2rem;
  text-align: center;
}
.intro .title {
    margin-bottom: 2.5rem;
}
.sandbox {
  padding: 5rem 0;
}
.quote { 
    background-image: url('/assets/img/quote.png');
    background-repeat: no-repeat;
    background-position: center top;

    .image {
        width: 100px;
    }
}
.notification {
    border-radius: 0;
}
.notification.is-gradient, .notification.is-passlo {
    color: $white;
}
.tile.notification {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.breadcrumbs {
    padding: 1rem 0;
    background-color: $white;
    border-bottom: 1px solid $grey-light;
}
.is-shady {
    background: #fff;
    box-shadow: rgba(0, 0, 0, .1) 0 1px 0;
    border-radius: 4px;
    margin: 10px;
}
.is-shady:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, .13), 0 6px 6px rgba(0, 0, 0, .19);
}
.break:not(:last-child) {
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    border-bottom: 2px solid $grey-light;
}
.section.break {
    padding-bottom: 3rem;
    margin-bottom: 0;
}
.blog-post {
    .notification {
        padding: 8rem 0;
        background-size: cover;
        background-repeat:no-repeat;
        background-position: center center;
    }
}
.get-started {
    background-image: url('/assets/img/rocket.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: -20px 20px;
}
footer {
    margin-bottom: 0 !important;
    box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.1);
}
.footer-label {
    color: #717171;
    margin-bottom: 1em;
}
.footer-menu a {
    color: #616161;
    padding: 0.1em 0;
    display: block;
    font-size: 0.8em;
}
.footer-menu a:hover {
    color: $passlo;
}
#copy-footer {
    padding: 1.5em;
}

@media screen and (max-width: 1023px) {
    .button.is-large {
        font-size: 1rem;
    }
    .button.demo {
        margin-top: 0.25rem;
        font-size: 0.8rem;
    }
}
