@import 'variables/colors.scss';

.pricing-table {
    margin-bottom: 3rem !important;
}

.pricing-table .pricing-plan {
    background-color: $white;
    border-radius: 3px;
    list-style-type: none;
    -webkit-transition: .25s;
    transition: .25s;
    margin: .5em
}

.pricing-table .pricing-plan .plan-header {
    background-color: $grey-ligh-ter;
    text-align: center;
    padding: 0.5rem;
    color: #888;
    font-style: italic;
}

.pricing-table .pricing-plan .plan-title,
.pricing-table .pricing-plan .plan-subtitle {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: $white;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1.5rem 0 0 0;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center
}

.pricing-table .pricing-plan .plan-subtitle {
    font-size: 1rem;
    font-weight: 300;
    padding: 0;
}

.pricing-table .pricing-plan .plan-item {
    padding: .75em;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center;
    font-size: .9em;
    color: #7a7a7a
}

.pricing-table .pricing-plan .plan-item .fa-check {
    color: #23d160;
}

.pricing-table .pricing-plan .plan-price {
    background-color: $white;
    font-size: .75rem;
    font-weight: 400;
    padding: .75em;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center
}

.pricing-table .pricing-plan .plan-price .plan-price-amount {
    font-size: 3rem;
    font-weight: 800
}

.pricing-table .pricing-plan .plan-price .plan-price-amount .plan-price-currency {
    vertical-align: super;
    font-size: 1rem!important;
    font-weight: 400!important;
    color: #4a4a4a;
    margin-right: .25rem
}

.pricing-table .pricing-plan .plan-footer {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: .75em;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center;
    margin-top: auto
}

.pricing-table .pricing-plan.is-active {
    -webkit-box-shadow: 0 8px 12px 0 rgba(122, 122, 122, .25);
    box-shadow: 0 8px 12px 0 rgba(122, 122, 122, .25);
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
}

.pricing-table .pricing-plan.is-active .plan-price .plan-price-amount {
    color: #00d1b2
}

.pricing-table .pricing-plan.is-active .plan-footer .button {
    background-color: #00d1b2;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-active .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-active .plan-footer .button:hover {
    background-color: #00c4a7;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-active .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-active .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(0, 209, 178, .25);
    box-shadow: 0 0 .5em rgba(0, 209, 178, .25);
    color: #fff
}

.pricing-table .pricing-plan.is-active .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-active .plan-footer .button:active {
    background-color: #00b89c;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-active .plan-footer .button[disabled] {
    background-color: #00d1b2;
    border-color: transparent
}

.pricing-table .pricing-plan.is-white .plan-price .plan-price-amount {
    color: #fff
}

.pricing-table .pricing-plan.is-white .plan-footer .button {
    background-color: #fff;
    border-color: transparent;
    color: #0a0a0a
}

.pricing-table .pricing-plan.is-white .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-white .plan-footer .button:hover {
    background-color: #f9f9f9;
    border-color: transparent;
    color: #0a0a0a
}

.pricing-table .pricing-plan.is-white .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-white .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(255, 255, 255, .25);
    box-shadow: 0 0 .5em rgba(255, 255, 255, .25);
    color: #0a0a0a
}

.pricing-table .pricing-plan.is-white .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-white .plan-footer .button:active {
    background-color: #f2f2f2;
    border-color: transparent;
    color: #0a0a0a
}

.pricing-table .pricing-plan.is-white .plan-footer .button[disabled] {
    background-color: #fff;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-black .plan-price .plan-price-amount {
    color: #0a0a0a
}

.pricing-table .pricing-plan.is-black .plan-footer .button {
    background-color: #0a0a0a;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-black .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-black .plan-footer .button:hover {
    background-color: #040404;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-black .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-black .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(10, 10, 10, .25);
    box-shadow: 0 0 .5em rgba(10, 10, 10, .25);
    color: #fff
}

.pricing-table .pricing-plan.is-black .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-black .plan-footer .button:active {
    background-color: #000;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-black .plan-footer .button[disabled] {
    background-color: #0a0a0a;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-light .plan-price .plan-price-amount {
    color: #f5f5f5
}

.pricing-table .pricing-plan.is-light .plan-footer .button {
    background-color: #f5f5f5;
    border-color: transparent;
    color: #363636
}

.pricing-table .pricing-plan.is-light .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-light .plan-footer .button:hover {
    background-color: #eee;
    border-color: transparent;
    color: #363636
}

.pricing-table .pricing-plan.is-light .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-light .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(245, 245, 245, .25);
    box-shadow: 0 0 .5em rgba(245, 245, 245, .25);
    color: #363636
}

.pricing-table .pricing-plan.is-light .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-light .plan-footer .button:active {
    background-color: #e8e8e8;
    border-color: transparent;
    color: #363636
}

.pricing-table .pricing-plan.is-light .plan-footer .button[disabled] {
    background-color: #f5f5f5;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-dark .plan-price .plan-price-amount {
    color: #363636
}

.pricing-table .pricing-plan.is-dark .plan-footer .button,
.pricing-table .pricing-plan.is-dark .plan-header {
    background-color: #363636;
    border-color: transparent;
    color: #f5f5f5
}

.pricing-table .pricing-plan.is-dark .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-dark .plan-footer .button:hover {
    background-color: #2f2f2f;
    border-color: transparent;
    color: #f5f5f5
}

.pricing-table .pricing-plan.is-dark .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-dark .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(54, 54, 54, .25);
    box-shadow: 0 0 .5em rgba(54, 54, 54, .25);
    color: #f5f5f5
}

.pricing-table .pricing-plan.is-dark .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-dark .plan-footer .button:active {
    background-color: #292929;
    border-color: transparent;
    color: #f5f5f5
}

.pricing-table .pricing-plan.is-dark .plan-footer .button[disabled] {
    background-color: #363636;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-primary .plan-price .plan-price-amount {
    color: #00d1b2
}

.pricing-table .pricing-plan.is-primary .plan-footer .button,
.pricing-table .pricing-plan.is-primary .plan-header {
    background-color: #00d1b2;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-primary .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-primary .plan-footer .button:hover {
    background-color: #00c4a7;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-primary .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-primary .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(0, 209, 178, .25);
    box-shadow: 0 0 .5em rgba(0, 209, 178, .25);
    color: #fff
}

.pricing-table .pricing-plan.is-primary .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-primary .plan-footer .button:active {
    background-color: #00b89c;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-primary .plan-footer .button[disabled] {
    background-color: #00d1b2;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-link .plan-price .plan-price-amount {
    color: #3273dc
}

.pricing-table .pricing-plan.is-link .plan-footer .button,
.pricing-table .pricing-plan.is-link .plan-header {
    background-color: #3273dc;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-link .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-link .plan-footer .button:hover {
    background-color: #276cda;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-link .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-link .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(50, 115, 220, .25);
    box-shadow: 0 0 .5em rgba(50, 115, 220, .25);
    color: #fff
}

.pricing-table .pricing-plan.is-link .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-link .plan-footer .button:active {
    background-color: #2366d1;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-link .plan-footer .button[disabled] {
    background-color: #3273dc;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-info .plan-price .plan-price-amount {
    color: #209cee
}

.pricing-table .pricing-plan.is-info .plan-footer .button,
.pricing-table .pricing-plan.is-info .plan-header {
    background-color: #209cee;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-info .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-info .plan-footer .button:hover {
    background-color: #1496ed;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-info .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-info .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(32, 156, 238, .25);
    box-shadow: 0 0 .5em rgba(32, 156, 238, .25);
    color: #fff
}

.pricing-table .pricing-plan.is-info .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-info .plan-footer .button:active {
    background-color: #118fe4;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-info .plan-footer .button[disabled] {
    background-color: #209cee;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-success .plan-price .plan-price-amount {
    color: #23d160
}

.pricing-table .pricing-plan.is-success .plan-footer .button {
    background-color: #23d160;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-success .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-success .plan-footer .button:hover {
    background-color: #22c65b;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-success .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-success .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(35, 209, 96, .25);
    box-shadow: 0 0 .5em rgba(35, 209, 96, .25);
    color: #fff
}

.pricing-table .pricing-plan.is-success .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-success .plan-footer .button:active {
    background-color: #20bc56;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-success .plan-footer .button[disabled] {
    background-color: #23d160;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-warning .plan-price .plan-price-amount {
    color: #ffdd57
}

.pricing-table .pricing-plan.is-warning .plan-footer .button {
    background-color: #ffdd57;
    border-color: transparent;
    color: rgba(0, 0, 0, .7)
}

.pricing-table .pricing-plan.is-warning .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-warning .plan-footer .button:hover {
    background-color: #ffdb4a;
    border-color: transparent;
    color: rgba(0, 0, 0, .7)
}

.pricing-table .pricing-plan.is-warning .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-warning .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(255, 221, 87, .25);
    box-shadow: 0 0 .5em rgba(255, 221, 87, .25);
    color: rgba(0, 0, 0, .7)
}

.pricing-table .pricing-plan.is-warning .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-warning .plan-footer .button:active {
    background-color: #ffd83d;
    border-color: transparent;
    color: rgba(0, 0, 0, .7)
}

.pricing-table .pricing-plan.is-warning .plan-footer .button[disabled] {
    background-color: #ffdd57;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pricing-table .pricing-plan.is-danger .plan-price .plan-price-amount {
    color: #ff3860
}

.pricing-table .pricing-plan.is-danger .plan-footer .button {
    background-color: #ff3860;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-danger .plan-footer .button.is-hovered,
.pricing-table .pricing-plan.is-danger .plan-footer .button:hover {
    background-color: #ff2b56;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-danger .plan-footer .button.is-focused,
.pricing-table .pricing-plan.is-danger .plan-footer .button:focus {
    border-color: transparent;
    -webkit-box-shadow: 0 0 .5em rgba(255, 56, 96, .25);
    box-shadow: 0 0 .5em rgba(255, 56, 96, .25);
    color: #fff
}

.pricing-table .pricing-plan.is-danger .plan-footer .button.is-active,
.pricing-table .pricing-plan.is-danger .plan-footer .button:active {
    background-color: #ff1f4b;
    border-color: transparent;
    color: #fff
}

.pricing-table .pricing-plan.is-danger .plan-footer .button[disabled] {
    background-color: #ff3860;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}
